exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ads-js": () => import("./../../../src/pages/ads.js" /* webpackChunkName: "component---src-pages-ads-js" */),
  "component---src-pages-ads-old-js": () => import("./../../../src/pages/ads-old.js" /* webpackChunkName: "component---src-pages-ads-old-js" */),
  "component---src-pages-dragon-js": () => import("./../../../src/pages/dragon.js" /* webpackChunkName: "component---src-pages-dragon-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leanux-js": () => import("./../../../src/pages/leanux.js" /* webpackChunkName: "component---src-pages-leanux-js" */),
  "component---src-pages-marketplace-js": () => import("./../../../src/pages/marketplace.js" /* webpackChunkName: "component---src-pages-marketplace-js" */),
  "component---src-pages-mobile-heartbeat-js": () => import("./../../../src/pages/mobile-heartbeat.js" /* webpackChunkName: "component---src-pages-mobile-heartbeat-js" */),
  "component---src-pages-neocova-js": () => import("./../../../src/pages/neocova.js" /* webpackChunkName: "component---src-pages-neocova-js" */),
  "component---src-pages-personalization-js": () => import("./../../../src/pages/personalization.js" /* webpackChunkName: "component---src-pages-personalization-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-salesquoting-js": () => import("./../../../src/pages/salesquoting.js" /* webpackChunkName: "component---src-pages-salesquoting-js" */),
  "component---src-pages-startup-js": () => import("./../../../src/pages/startup.js" /* webpackChunkName: "component---src-pages-startup-js" */),
  "component---src-pages-theskills-js": () => import("./../../../src/pages/theskills.js" /* webpackChunkName: "component---src-pages-theskills-js" */),
  "component---src-pages-theskills-old-js": () => import("./../../../src/pages/theskills-old.js" /* webpackChunkName: "component---src-pages-theskills-old-js" */)
}

